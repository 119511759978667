import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import DownloadButton from "../../components/downloadButton";

const FRCG = () => {
    const headerMarginValue = '30px'
    const ButtonStylesClass = 'BHRA-item-buttons'       
    const folder = '/downloads/resources/FRCG'

    return (
        <Layout>
            <div >
                <h1>Fermi Race Card Game</h1>
                <Star />
                <p style={{textAlign:'start'}}>The Fermi Race Card game challenges two teams of players to strategically maneuver to be the first to assemble the parts of the Fermi Gamma-ray Space Telescope satellite and then use it to observe five astronomical objects. As a team builds their satellite, they must strategically manage their resources in competition with the opposing team. To win, players must successfully have their operational Fermi satellite observe five different types of cosmic gamma-ray sources.</p>
                <DownloadButton text={"Instruction Guide"} link={`${folder}/RuleGuide08.pdf`} buttonStyles = {ButtonStylesClass}/>
                <p style={{textAlign:'start'}}>Below are links to the pdf files of all the cards used in the Fermi Race Card game. The card image files are each ~200kb in size. The cards should be printed in color and on cardstock for best results. You must have <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">Adobe Reader</a> to view these files.</p>
                <p style={{textAlign:'start'}}>Each of the cards need to be printed multiple times, the quantity of each type of card in one Fermi Race Card game deck is listed in the how to print online pdf file.</p>
                <DownloadButton text={"How to Print PDF"} link={`${folder}/howtoprintonline.pdf`} buttonStyles = {ButtonStylesClass}/>
                <p style={{textAlign:'start'}}>Each player should have one, double-sided, playing mat</p>
                <DownloadButton text={"Double Sided Playing Mat"} link={`${folder}/F2board09.pdf`} buttonStyles = {ButtonStylesClass}/>

                <h2 style={{margin: headerMarginValue}}>Single Sided Cards</h2>
                <DownloadButton text={"Single Sided Cards"} link={`${folder}/gracecardSingl09.pdf`} buttonStyles = {ButtonStylesClass}/>

                <h2 style={{margin: headerMarginValue}}>The Double Sided Cards</h2>
                <p style={{textAlign:'start'}}>In parenthesis is the number of each needed for a complete set.</p>
                <div className='double-sided-card-container'>
                    <DownloadButton text={"1a(2)"} link={`${folder}/grdouble1a08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"1b(2)"} link={`${folder}/grdouble1b08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"2a(2)"} link={`${folder}/grdouble2a08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"2b(2)"} link={`${folder}/grdouble2b08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"3a(2)"} link={`${folder}/grdouble3a08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"3b(2)"} link={`${folder}/grdouble3b08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"4a(4)"} link={`${folder}/grdouble4a08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"4b(2)"} link={`${folder}/grdouble4b08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"4c(2)"} link={`${folder}/grdouble4c08.pdf`} buttonStyles = 'double-sided-buttons'/>
                    <DownloadButton text={"5a(4)"} link={`${folder}/grdouble5a08.pdf`} buttonStyles = 'double-sided-buttons'/>
                </div>
                <p style={{textAlign:'start'}}>That's it, that's all you need to play the Fermi Race Card game.</p>
                <p style={{textAlign:'start'}}>Enjoy the game!</p>
            </div>
        </Layout>
  )
}

export default FRCG